import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Heading = makeShortcode("Heading");
const ExternalLink = makeShortcode("ExternalLink");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Heading frontmatter={props.pageContext.frontmatter} mdxType="Heading" />
    <p>{`Mechanic is an automation and development platform for Shopify.`}</p>
    <p>{`In addition to the expansive public task directory, the platform documentation is very thorough and the support is beyond superb. The team at Mechanic are highly responsive to helping empower your platform usage, regardless of whether you are an advanced Shopify developer, or just getting your feet wet.`}</p>
    <p>{`Perhaps one of Mechanic's best features is its ability to let you just concentrate on solving a merchant’s immediate business needs without all of the repetitive scaffolding required to develop each new microservice. Almost everything is managed for you - task queuing and processing, form options for merchant task configuration (the task admin UI), API rate limiting, error logging, webhook configuration, transactional email, inbound email parsing, and so much more.`}</p>
    <p>{`The `}<ExternalLink href="https://learn.mechanic.dev/" mdxType="ExternalLink">{`Mechanic documentation`}</ExternalLink>{` site has a wealth of information to help anyone get started with using the platform, whether they are just enabling existing tasks from the public library, or developing their own set of custom tasks.`}</p>
    <p>{`A small sample of the ecommerce automations developed by Tekhaus on the Mechanic platform:`}</p>
    <ul>
      <li parentName="ul">{`A custom order status notification flow that integrates with an Asana task board`}</li>
      <li parentName="ul">{`Complex backend support for multi-location local pickup and delivery`}</li>
      <li parentName="ul">{`Destructure virtual bundles into their components without product duplication or hidden variants`}</li>
      <li parentName="ul">{`Scheduled product, inventory, and customer exports in Shopify CSV format`}</li>
      <li parentName="ul">{`Auto-fulfill and add tracking to orders by parsing inbound dropshipper fulfillment emails`}</li>
      <li parentName="ul">{`Send localized payment reminder emails by customer’s checkout language`}</li>
      <li parentName="ul">{`Email daily pick lists (e.g. unfulfilled items) by location`}</li>
      <li parentName="ul">{`Run nightly bulk inventory updates via FTP downloads`}</li>
      <li parentName="ul">{`Re-assign warehouse order fulfillments by geographic distribution`}</li>
      <li parentName="ul">{`Add order risk levels by outcomes found in transaction receipts`}</li>
      <li parentName="ul">{`Tag customers by Smile.io VIP tier`}</li>
    </ul>
    <p>{`If any of the above automations resonate with the goals you're trying to accomplish, then `}<Link to="/contact" mdxType="Link">{`contact Tekhaus`}</Link>{` to learn more.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      